import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Clients, { ClientsForm } from "./pages/Clients";
import Zones, { ZonesForm } from "./pages/Zones";
import ZonesUser, { ZonesUserForm } from "./pages/ZonesUser";
import Recursos, { RecursosForm } from "./pages/Recursos";
import Reserves from "./pages/Reserves";
import ReservesDetall from "pages/ReservesDetall";
import Productes from "./pages/Productes";
import ProductesDetall from "pages/ProductesDetall";
import AgendesProducte from "./pages/AgendesProducte";
import AgendesProducteDetall from "pages/AgendesProducteDetall";
import Users from "./pages/Users";
import NotFoundPage from "./pages/404";
import UsersDetall from "pages/UsersDetall";
import ZonesDetall from "pages/ZonesDetall";
import Parametres from "pages/Parametres";
import InformeZonaVenedor, { InformeZonaVenedorForm } from "pages/InformeZonaVenedor";
import UsRecurs from "pages/usRecurs";

const AppRoutes = () => {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path="client">
                <Route index element={<Clients />} />
                <Route path="form">
                    <Route index element={<ClientsForm />} />
                    <Route path=":id" element={<ClientsForm />} />
                </Route>
            </Route>
            <Route path="zona">
                <Route index element={<Zones />} />
                <Route path="form">
                    <Route index element={<ZonesDetall />} />
                    <Route path=":id" element={<ZonesDetall />} />
                </Route>
            </Route>
            <Route path="zonaUser">
                <Route index element={<ZonesUser />} />
                <Route path="form">
                    <Route index element={<ZonesUserForm />} />
                    <Route path=":id" element={<ZonesUserForm />} />
                </Route>
            </Route>
            <Route path="recurs">
                <Route index element={<Recursos />} />
                <Route path="form">
                    <Route index element={<RecursosForm />} />
                    <Route path=":id" element={<RecursosForm />} />
                </Route>
            </Route>
            <Route path="reserva">
                <Route index element={<Reserves />} />
                <Route path="form">
                    <Route index element={<ReservesDetall />} />
                    <Route path=":id" element={<ReservesDetall />} />
                </Route>
            </Route>
            <Route path="producte">
                <Route index element={<Productes />} />
                <Route path="form">
                    <Route index element={<ProductesDetall />} />
                    <Route path=":id" element={<ProductesDetall />} />
                </Route>
            </Route>
            <Route path="agendaProducte">
                <Route index element={<AgendesProducte />} />
                <Route path="form">
                    <Route index element={<AgendesProducteDetall />} />
                    <Route path=":id" element={<AgendesProducteDetall />} />
                </Route>
            </Route>
            <Route path="user">
                <Route index element={<Users />} />
                <Route path="form">
                    <Route index element={<UsersDetall />} />
                    <Route path=":id" element={<UsersDetall />} />
                </Route>
            </Route>
            <Route path="parametre" >
                <Route index element={<Parametres />} />
            </Route>
            <Route path="informeZonaVenedor">
                <Route index element={<InformeZonaVenedor />} />
                <Route path="form">
                    <Route index element={<InformeZonaVenedorForm />} />
                    <Route path=":id" element={<InformeZonaVenedorForm />} />
                </Route>
            </Route>
            <Route path="usRecurs" element={<UsRecurs />} />
            <Route path="404" element={<NotFoundPage />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
    );
};

export default AppRoutes;
