import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import MuiGrid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Form, useFormContext } from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import ZonesUser from "./ZonesUser";

const UsersForm = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { data } = useFormContext();

    const passwordContent = (
        <MuiGrid container spacing={2}>
            <GridFormField
                name="password"
                required={data?.id == null}
                sx={{ backgroundColor: "white" }}
                autoComplete="new-password"
                xs={6}
            />
            <GridFormField
                name="repeat"
                required={data?.id == null}
                sx={{ backgroundColor: "white" }}
                autoComplete="new-password"
                xs={6}
            />
        </MuiGrid>
    );

    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="code" disabled={data?.id != null} xs={4} />
            <MuiGrid item xs={6} />
            {data?.id ? <GridFormField name="source" disabled xs={2} /> : null}
            <GridFormField name="givenName" xs={6} />
            <GridFormField name="familyName" xs={6} />
            <GridFormField name="email" xs={12} />
            <GridFormField
                name="roles"
                hiddenEnumValues={["MANAGER"]}
                xs={10}
            />
            <GridFormField name="terminal" xs={2} />
            <MuiGrid item xs={12}>
                {data?.id != null ? (
                    <Alert
                        icon={false}
                        variant="outlined"
                        sx={{
                            color: theme.palette.text.primary,
                            borderColor: theme.palette.divider,
                            backgroundColor: theme.palette.grey[100],
                            "& .MuiAlert-message": { width: "100%" },
                        }}
                    >
                        <AlertTitle>{t("users.password.title")}</AlertTitle>
                        {passwordContent}
                        <Typography variant="caption">
                            {t("users.password.caption")}
                        </Typography>
                    </Alert>
                ) : (
                    passwordContent
                )}
            </MuiGrid>
            <GridFormField name="active" />
        </MuiGrid>
    );
};

const UsersFormContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("indicadores.common.form.tabs")}
                >
                    <Tab label={t("users.title")} value="0" />
                    <Tab
                        label={t("zonesUser.title")}
                        value={"1"}
                        disabled={!data?.id}
                    />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <UsersForm />
            </TabPanel>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                <ZonesUser userId={data?.id} />
            </TabPanel>
        </TabContext>
    );
};

const UsersDetall = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <Form
                resourceName="user"
                id={id}
                perspectives={["roles"]}
                titleCreate={t("users.create")}
                titleUpdate={t("users.update")}
                size="small"
                includeFormElement
                onGoBack={() => navigate(-1)}
            >
                <UsersFormContent />
            </Form>
        </Box>
    );
};
export default UsersDetall;
