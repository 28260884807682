import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { addComponentResourceBundles } from '@programari-limit/base-react';
import translationCa from './translationCa';
import translationEs from './translationEs';
import translationEn from './translationEn';

const resources = {
    ca: { translation: translationCa },
    es: { translation: translationEs },
    en: { translation: translationEn },
};

i18next.use(LanguageDetector).use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});

addComponentResourceBundles(i18next);

export default i18next;