import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Form } from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import MuiGrid from "@mui/material/Grid";
import { suffixValueFormatter } from "utils/muiUtils";

export const ClientsForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    return (
        <Form
            resourceName="client"
            id={id}
            titleCreate={t("clients.create")}
            titleUpdate={t("clients.update")}
            onGoBack={() => navigate(-1)}
        >
            <MuiGrid container spacing={2}>
                <GridFormField name="codi" lg={2} />
                <GridFormField name="nom" lg={5} />
                <GridFormField name="tipus" lg={3} />
                <GridFormField name="preu" lg={2} suffix={' €'}/>
                <GridFormField name="observacions" lg={12} />
            </MuiGrid>
        </Form>
    );
};

const Clients = () => {
    const { t } = useTranslation();
    const columns = [
        {
            name: "codi",
            flex: 1,
        },
        {
            name: "nom",
            flex: 4,
        },
        {
            name: "tipus",
            flex: 1.5,
        },
        {
            name: "preu",
            flex: 1.5,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
    ];
    return (
        <Grid
            title={t("clients.title")}
            resourceName="client"
            columns={columns}
            formRoutePath="form"
            density="compact"
        />
    );
};

export default Clients;
