import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Form } from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import MuiGrid from "@mui/material/Grid";
import { suffixValueFormatter } from "utils/muiUtils";
import { columnesClients } from "sharedAdvancedSearch/advancedSearchColumns";
import { getFielYellow } from "./Reserves";
import { Box, darken } from "@mui/material";

export const ReservesLiniaForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Form
            resourceName="reservaLinia"
            id={id}
            titleCreate={t("reservesLinia.create")}
            titleUpdate={t("reservesLinia.update")}
            onGoBack={() => navigate(-1)}
        >
            <MuiGrid container spacing={2}>
                <GridFormField name="quantitat" lg={10} />
                <GridFormField name="preu" lg={10} />
                <GridFormField name="importDescompte" lg={10} />
                <GridFormField name="importLinia" lg={10} />
                <GridFormField name="tipusClient" lg={10} />
                <GridFormField name="reserva" lg={10} />
                <GridFormField name="client" lg={10} />
            </MuiGrid>
        </Form>
    );
};

const ReservesLinia = (props) => {
    const { producte } = props;
    const { t } = useTranslation();

    const columns = [
        {
            name: "client",
            flex: 3,
            formFieldProps: {
                advancedSearchColumns: columnesClients,
            },
        },
        {
            name: "tipusClient",
            flex: 1.5,
        },
        {
            name: "quantitat",
            flex: 1,
        },
        {
            name: "preu",
            flex: 1.5,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "importDescompte",
            flex: 1.5,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "importLinia",
            flex: 1.5,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
    ];

    return (
        <Box
            sx={{
                height: "100%",
                "& .cellWarning": {
                    bgcolor: (theme) => getFielYellow(theme.palette.mode),
                },
                "& .MuiDataGrid-row:hover .cellWarning": {
                    bgcolor: (theme) =>
                        `${darken(
                            getFielYellow(theme.palette.mode),
                            0.1
                        )}!important`,
                },
            }}
        >
            <Grid
                title={t("reservesLinia.title")}
                resourceName="reservaLinia"
                columns={columns}
                // formRoutePath="form"
                density="compact"
                fixedFilter={
                    producte ? `reserva.id : '${producte?.id}'` : undefined
                }
                inlineFormStaticData={{
                    reserva: { id: producte?.id },
                    entradaManual: true,
                }}
                getCellClassName={({ row, field }) => {
                    return row?.entradaManual ? "cellWarning" : "";
                }}
            />
        </Box>
    );
};

export default ReservesLinia;
