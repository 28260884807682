import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@programari-limit/base-react";

export const Users = () => {
    const { t } = useTranslation();

    const columns = [
        {
            name: "code",
            flex: 1.5,
        },
        {
            name: "givenName",
            flex: 3,
        },
        {
            name: "familyName",
            flex: 3,
        },
        {
            name: "email",
            flex: 2.5,
        },
        {
            name: "terminal",
            flex: 1,
        },
        {
            name: "numZones",
            flex: 1,
        },
        {
            name: "admin",
            flex: 1,
        },
        {
            name: "venedor",
            flex: 1,
        },
        {
            name: "active",
            flex: 1,
        },
    ];

    return (
        <Grid
            title={t("users.title")}
            resourceName="user"
            perspectives={["roles"]}
            columns={columns}
            formRoutePath="form"
        />
    );
};

export default Users;
