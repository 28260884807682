import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Form, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { Box } from "@mui/material";
import { suffixValueFormatter } from "utils/muiUtils";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    columnesProducte,
    columnesRequisitRecurs,
    columnesZones,
} from "sharedAdvancedSearch/advancedSearchColumns";

const AgendesProducteForm = () => {
    const { data } = useFormContext();

    return (
        <MuiGrid container spacing={2} sx={{ mt: 2 }}>
            <GridFormField name="nom" lg={4} />
            <GridFormField
                name="producte"
                lg={4}
                advancedSearchColumns={columnesProducte}
                advancedSearchMaxWidth="lg"
            />
            <GridFormField
                name="requeriment"
                lg={4}
                advancedSearchColumns={columnesRequisitRecurs}
            />
            <GridFormField name="data" lg={3} />
            <GridFormField name="horaInici" lg={2.5} />
            <GridFormField name="horaFi" lg={2.5} />
            <GridFormField
                name="zona"
                lg={4}
                advancedSearchColumns={columnesZones}
            />
            <GridFormField name="countReserves" lg={2} />
            <GridFormField name="ocupacio" lg={2} />
            <GridFormField name="disponibilitat" lg={2} />
            <GridFormField name="capacitat" lg={2} />
            <GridFormField name="actiu" lg={2} />
        </MuiGrid>
    );
};

const ReservesGrid = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();

    const columns = [
        {
            name: "data",
            flex: 3,
        },
        {
            name: "numero",
            flex: 3,
        },
        {
            name: "adults",
            flex: 2,
        },
        {
            name: "infants",
            flex: 2,
        },
        {
            name: "bebes",
            flex: 2,
        },
        {
            name: "importTotal",
            flex: 2.5,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
    ];

    return (
        <Grid
            title={t("reserves.title")}
            resourceName="reserva"
            columns={columns}
            rowAdditionalActions={[
                {
                    icon: "search",
                    label: t("comu.detall"),
                    onClick: (event) => {
                        window.open(`/reserva/form/${event?.id}`);
                    },
                    showInMenu: false,
                },
            ]}
            readOnly
            density="compact"
            fixedFilter={`agendaProducte.id : '${data?.id}'`}
        />
    );
};

const AgendaRecursGrid = (props) => {
    const {} = props;
    const { t } = useTranslation();
    const { data } = useFormContext();

    const columns = [
        {
            name: "codiRecurs",
            flex: 1,
        },
        {
            name: "nomRecurs",
            flex: 1,
        },
        {
            name: "dataHoraInici",
            flex: 1,
        },
        {
            name: "dataHoraFi",
            flex: 1,
        },
    ];

    return (
        <Grid
            title={t("recursos.title")}
            resourceName="agendaRecurs"
            columns={columns}
            readOnly
            density={"compact"}
            perspectives={["recurs"]}
            fixedFilter={`agendaProducte.id : '${data?.id}'`}

            // inlineFormStaticData={{
            //     requeriment: {
            //         id: currentOpenRequisitRecurs?.id,
            //     },
            // }}
        />
    );
};

const AgendaClientGrid = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const columns = [
        {
            name: "codiClient",
            flex: 1,
        },
        {
            name: "client",
            flex: 1,
        },
        {
            name: "tipusClient",
            flex: 1.5,
        },
        {
            name: "preu",
            flex: 1,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
    ];
    return (
        <Grid
            title={t("clients.title")}
            resourceName="agendaClient"
            columns={columns}
            density="compact"
            readOnly
            perspectives={["client"]}
            fixedFilter={`agendaProducte.id : '${data?.id}'`}
        />
    );
};

const AgendesProductesFormContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("indicadores.common.form.tabs")}
                >
                    <Tab label={t("agendesProducte.update")} value="0" />
                    <Tab
                        label={t("clients.title")}
                        value={"1"}
                        disabled={!data?.id}
                    />
                    <Tab
                        label={t("recursos.title")}
                        value="2"
                        disabled={!data?.id}
                    />
                    <Tab
                        label={t("reserves.title")}
                        value="3"
                        disabled={!data?.id}
                    />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <AgendesProducteForm />
            </TabPanel>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                <AgendaClientGrid />
            </TabPanel>
            <TabPanel value="2" sx={{ height: "100%", p: 0 }}>
                <AgendaRecursGrid />
            </TabPanel>
            <TabPanel value="3" sx={{ height: "100%", p: 0 }}>
                <ReservesGrid />
            </TabPanel>
        </TabContext>
    );
};

const AgendesProducteDetall = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <Form
                resourceName="agendaProducte"
                id={id}
                titleCreate={t("agendesProducte.create")}
                titleUpdate={t("agendesProducte.update")}
                onGoBack={() => navigate(-1)}
                size="small"
                readOnly
            >
                <AgendesProductesFormContent />
            </Form>
        </Box>
    );
};
export default AgendesProducteDetall;
