import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@programari-limit/base-react";


const Zones = () => {
    const { t } = useTranslation();
    const columns = [
        {
            name: "codi",
            flex: 1,
        },
        {
            name: "nom",
            flex: 3,
        },
        {
            name: "observacions",
            flex: 3,
        },
    ];
    return (
        <Grid
            title={t("zones.title")}
            resourceName="zona"
            columns={columns}
            formRoutePath="form"
        />
    );
};

export default Zones;
