export const suffixValueFormatter = (formatterParams, ref) => {
    const column = formatterParams.api
        .getAllColumns()
        .find((e) => e.field === formatterParams.field);
    const row = formatterParams.api.getRow(formatterParams.id);
    if (ref && formatterParams.value != null) {
        if (row?.[ref]?.description) {
            return `${formatterParams.value} ${row?.[ref].description}`;
        }
        return `${formatterParams.value} ${row?.[ref] ?? ''}`;
    }
    if (formatterParams.value != null) {
        return formatterParams.value + column?.formFieldProps.suffix;
    }
    return formatterParams.value;

};
