import { useFormContext } from '@programari-limit/base-react';
import { Box } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

const FormErrorBox = ({ fieldName, children }) => {
    const formContext = useFormContext();
    const contextFieldErrors = formContext?.fieldErrors?.filter((e) => e.field === fieldName);
    const fieldError = contextFieldErrors?.find((e) => e.field === fieldName);

    return (
        <>
            <Box
                sx={{
                    borderRadius: 1,
                    border: fieldError ? 1 : undefined,
                    borderColor: fieldError ? '#f44336' : '#c4c4c4',
                }}
            >
                {children}
            </Box>
            {fieldError && (
                <FormHelperText
                    sx={{
                        color: fieldError ? '#f44336' : undefined,
                    }}
                >
                    {fieldError.message}
                </FormHelperText>
            )}
        </>
    );
};

export default FormErrorBox;
