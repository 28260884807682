import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Form } from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import MuiGrid from "@mui/material/Grid";
import { suffixValueFormatter } from "utils/muiUtils";

export const ReservesDescompteForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    return (
        <Form
            resourceName="reservaDescompte"
            id={id}
            titleCreate={t("reservesDescompte.create")}
            titleUpdate={t("reservesDescompte.update")}
            onGoBack={() => navigate(-1)}
        >
            <MuiGrid container spacing={2}>
                <GridFormField name="reserva" lg={10} />
                <GridFormField name="tipus" lg={10} />
                <GridFormField name="importDescomptat" lg={10} />
                <GridFormField name="descripcio" lg={10} />
            </MuiGrid>
        </Form>
    );
};

const ReservesDescompte = (props) => {
    const { producte } = props;
    const { t } = useTranslation();

    const columns = [
        // {
        //     name: "reserva",
        //     flex: 1.3,
        // },
        {
            name: "tipus",
            flex: 1,
        },
        {
            name: "importDescomptat",
            flex: 1,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "descripcio",
            flex: 3,
        },
    ];
    return (
        <Grid
            title={t("reservesDescompte.title")}
            resourceName="reservaDescompte"
            columns={columns}
            density="compact"
            fixedFilter={producte ? `reserva.id : '${producte?.id}'` : undefined}
            inlineFormStaticData={{
                reserva: { id: producte?.id },
                entradaManual: true,
            }}
            // readOnly
        />
    );
};

export default ReservesDescompte;
