import {
    useBaseAppContext,
    useAuthContext,
} from "@programari-limit/base-react";
import Axios from "axios";
import { envVariable } from "@programari-limit/base-react";

const useChartRequest = () => {
    const { authToken } = useAuthContext();
    const { currentLanguage } = useBaseAppContext();

    const getChart = (route, params) => {
        return Axios.get(`${envVariable('REACT_APP_API_URL')}/${route}?${params}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Accept-Language': currentLanguage,
            },
        });
    };

    return getChart;
};

export default useChartRequest;
