import React from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Filter,
    useBaseAppContext,
    useResourceApi,
    useContentDialog,
} from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import {
    Box,
    Button,
    Divider,
    Icon,
    IconButton,
    Tooltip,
    Typography,
    darken,
} from "@mui/material";
import { suffixValueFormatter } from "utils/muiUtils";
import GridFilterField from "components/GridFilterField";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import {
    columnesAgendaProducte,
    columnesProducte,
    columnesUsuaris,
    columnesZones,
} from "sharedAdvancedSearch/advancedSearchColumns";
import { likeAny } from "utils/springFilterUtils";
import { useNavigate } from "react-router-dom";

export const getFielYellow = (mode) => "#f9f6aa";
export const getFieldHighlightRed = (mode) => "#ffe6e6";

const BotoExportacio = (props) => {
    const { resourceName, exportData, exportCallbacks } = props;
    const { t } = useTranslation();
    const {
        apiInitialized,
        apiResource,
        exec: apiExec,
        execHref: apiExecHref,
        execDownload: apiExecDownload,
    } = useResourceApi(resourceName);

    const download = () => {
        apiExecDownload("export", {
            data: exportData,
            callbacks: exportCallbacks,
        });
    };

    if (!apiInitialized) return;

    return (
        <Tooltip title={t("reserves.exportarA3")}>
            <Button
                variant="contained"
                onClick={() => download()}
                startIcon={<Icon>download</Icon>}
            >
                {t("reserves.exportarA3")}
            </Button>
        </Tooltip>
    );
};

const TotalsContent = (props) => {
    const { totals } = props;
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 5,
                mt: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("reserves.totals.adults")}
                </Typography>
                <Typography>{totals.adults}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("reserves.totals.infants")}
                </Typography>
                <Typography>{totals.infants}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("reserves.totals.bebes")}
                </Typography>
                <Typography>{totals.bebes}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("reserves.totals.efectiu")}
                </Typography>
                <Typography>{totals.importEfectiu + " €"}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("reserves.totals.targeta")}
                </Typography>
                <Typography>{totals.importTargeta + " €"}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("reserves.totals.reserves")}
                </Typography>
                <Typography>{totals.totalReserves}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("reserves.totals.total")}
                </Typography>
                <Typography>{totals.importTotal + " €"}</Typography>
            </Box>
        </Box>
    );
};

const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField
                name="agendaProducte"
                sm={6}
                md={4}
                lg={3}
                xl={4}
                advancedSearchColumns={columnesAgendaProducte}
                advancedSearchMaxWidth="lg"
            />
            <GridFilterField
                name="dataInici"
                sm={6}
                md={4}
                lg={3}
                xl={2}
                type="date"
            />
            <GridFilterField
                name="dataFi"
                sm={6}
                md={4}
                lg={3}
                xl={2}
                type="date"
            />
            <GridFilterField
                name="dataDisponibilitatInici"
                sm={6}
                md={4}
                lg={3}
                xl={2}
            />
            <GridFilterField
                name="dataDisponibilitatFi"
                sm={6}
                md={4}
                lg={3}
                xl={2}
            />
            <GridFilterField name="numero" sm={6} md={4} lg={3} xl={4} />
            <GridFilterField name="pagamentTipus" sm={6} md={4} lg={3} xl={2} />
            <GridFilterField
                name="user"
                sm={6}
                md={4}
                lg={3}
                advancedSearchColumns={columnesUsuaris}
                advancedSearchMaxWidth={"lg"}
            />
            <GridFilterField
                name="producte"
                sm={6}
                md={4}
                lg={3}
                advancedSearchColumns={columnesProducte}
                advancedSearchMaxWidth="lg"
            />
            <GridFilterField name="recurs" sm={6} md={4} lg={3} xl={3} />
            <GridFilterField
                name="zona"
                sm={6}
                md={4}
                lg={3}
                xl={3}
                advancedSearchColumns={columnesZones}
                advancedSearchMaxWidth="lg"
            />
            <GridFilterField name="tipus" sm={6} md={4} lg={3} xl={3} />
            <GridFilterField name="estat" sm={6} md={4} lg={3} xl={2} />

            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const exportSpringFilterBuilder = (data, setExportFixedFilter) => {
    const { and, equal, ge, gt, le, like, exists, isNotNull } =
        SpringFilterQueryBuilder;

    const filter = and(
        like("agendaProducte.nom", likeAny(data?.agendaProducte)),
        ge("data", data?.dataInici ? data?.dataInici + "T00:00:00" : undefined),
        le("data", data?.dataFi ? data?.dataFi + "T23:59:59" : undefined),
        ge(
            "dataDisponibilitat",
            data?.dataDisponibilitatInici?.substring(0, 19)
        ),
        le("dataDisponibilitat", data?.dataDisponibilitatFi?.substring(0, 19)),
        like("numero", likeAny(data?.numero)),
        equal("pagamentTipus", data?.pagamentTipus),
        equal("user.id", data?.user?.id),
        equal("producte.id", data?.producte?.id),
        equal("tipus", data?.tipus),
        equal("estat", data?.estat),
        equal("zona.id", data?.zona?.id),
        gt("importTotal", 0),
        data?.recurs &&
            exists(
                isNotNull(
                    "agendaProducte.agendesRecurs.recurs.id",
                    data?.recurs?.id
                )
            )
    ).toString();

    setExportFixedFilter(filter);
};

const springFilterBuilder = (data, setExportFixedFilter) => {
    const { and, equal, ge, le, like, exists, isNotNull } =
        SpringFilterQueryBuilder;

    exportSpringFilterBuilder(data, setExportFixedFilter);

    const filter = and(
        like("agendaProducte.nom", likeAny(data?.agendaProducte)),
        ge("data", data?.dataInici ? data?.dataInici + "T00:00:00" : undefined),
        le("data", data?.dataFi ? data?.dataFi + "T23:59:59" : undefined),
        ge(
            "dataDisponibilitat",
            data?.dataDisponibilitatInici?.substring(0, 19)
        ),
        le("dataDisponibilitat", data?.dataDisponibilitatFi?.substring(0, 19)),
        like("numero", likeAny(data?.numero)),
        equal("pagamentTipus", data?.pagamentTipus),
        equal("user.id", data?.user?.id),
        equal("producte.id", data?.producte?.id),
        equal("tipus", data?.tipus),
        equal("estat", data?.estat),
        equal("zona.id", data?.zona?.id),
        data?.recurs &&
            exists(
                equal(
                    "agendaProducte.agendesRecurs.recurs.id",
                    data?.recurs?.id
                )
            )
    ).toString();

    return filter;
};

const ReservesFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized, setExportFixedFilter } =
        props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"R_FILTER"}
                resourceName={"reserva"}
                persistentState
                springFilterBuilder={(data) =>
                    springFilterBuilder(data, setExportFixedFilter)
                }
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
                initialData={{ estat: "ACTIVA" }}
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

const Reserves = () => {
    const { t } = useTranslation();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [exportFixedFilter, setExportFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const navigate = useNavigate();
    const gridApiRef = React.useRef();
    const { currentLanguage } = useBaseAppContext();
    const { apiInitialized, exec: apiExec } = useResourceApi("reserva");
    const [totals, setTotals] = React.useState();
    const { dialog, openDialog } = useContentDialog("lg");

    const columns = [
        {
            name: "agendaProducte",
            flex: 3,
            minWidth: 220,
        },
        {
            name: "data",
            flex: 3,
            minWidth: 200,
        },
        {
            name: "numero",
            flex: 3,
            minWidth: 170,
        },
        {
            name: "adults",
            flex: 1.5,
            minWidth: 90,
        },
        {
            name: "infants",
            flex: 1.5,
            minWidth: 90,
        },
        {
            name: "bebes",
            flex: 1.5,
            minWidth: 90,
        },
        // {
        //     name: "pagamentTipus",
        //     flex: 2,
        //     minWidth: 130,
        // },
        {
            name: "importDescomptat",
            flex: 3,
            minWidth: 150,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "importEfectiu",
            flex: 3,
            minWidth: 150,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "importTargeta",
            flex: 3,
            minWidth: 150,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "importTotal",
            flex: 2.5,
            minWidth: 150,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "user",
            flex: 3,
            minWidth: 150,
        },
        {
            name: "zona",
            flex: 3,
            minWidth: 150,
        },
        {
            name: "estat",
            flex: 2,
            minWidth: 120,
        },
    ];

    const rowAdditionalActions = (params) => {
        const listActions = [
            {
                icon: "edit",
                label: t("comu.detall"),
                onClick: () => navigate(`form/${params?.id}`),
            },
            {
                icon: "lock",
                showInMenu: true,
                rowLinkAction: "R_ANULAR",
                rowLinkActionOnSuccess: () => obtenirTotals(),
            },
            {
                icon: "lock_open",
                showInMenu: true,
                rowLinkAction: "R_ACTIVAR",
                rowLinkActionOnSuccess: () => obtenirTotals(),
            },
        ];

        return listActions;
    };

    // const openTotalsDialog = () => {
    //     openDialog(
    //         t("reserves.title"),
    //         null,
    //         null,
    //         <Box
    //         // sx={{ height: "500px" }}
    //         >
    //             <Totals totals={totals} />
    //         </Box>,
    //         [
    //             {
    //                 value: "cancel",
    //                 contained: false,
    //                 startIcon: "close",
    //                 description: t("comu.tancar"),
    //             },
    //         ],
    //         (value, close) => {
    //             close();
    //         }
    //     );
    // };

    // Todo: S'ha posat aixo pq la llibreria no refresca la traduccio de l'accio sense refrescar el grid

    React.useEffect(() => {
        gridApiRef?.current?.doRefresh();
    }, [currentLanguage]);

    const obtenirTotals = () => {
        apiExec("exec_R_OBTENIR_TOTALS", {
            data: { springFilter: fixedFilter ?? "(estat : 'ACTIVA')" },
            callbacks: {
                setResponse: (response) => {
                    const resource = {
                        ...response.data,
                        _resource: response,
                        _links: response.links,
                    };
                    setTotals(resource);
                },
                setError: (error) => {},
            },
        });
    };

    React.useEffect(() => {
        if (apiInitialized) {
            obtenirTotals();
        }
    }, [apiInitialized, fixedFilter]);

    const exportData = {
        page: "UNPAGED",
        perspective: ["excel"],
        type: "ODS",
        filter: exportFixedFilter,
        field: [
            "refFactura",
            "dataFacturacio",
            "tipusIva",
            "id",
            "baseImposable",
            "importIva",
            "importReserva",
            "idProfessional",
            "raoSocial",
            "codiComptableClient",
        ],
    };

    return (
        <Box
            sx={{
                height: "100%",
                "& .cellWarning": {
                    bgcolor: (theme) => getFielYellow(theme.palette.mode),
                },
                "& .MuiDataGrid-row:hover .cellWarning": {
                    bgcolor: (theme) =>
                        `${darken(
                            getFielYellow(theme.palette.mode),
                            0.1
                        )}!important`,
                },
                "& .cellAnulada": {
                    bgcolor: (theme) =>
                        getFieldHighlightRed(theme.palette.mode),
                },
                "& .MuiDataGrid-row:hover .cellAnulada": {
                    bgcolor: (theme) =>
                        `${darken(
                            getFieldHighlightRed(theme.palette.mode),
                            0.1
                        )}!important`,
                },
            }}
        >
            <Grid
                apiRef={gridApiRef}
                title={t("reserves.title")}
                resourceName="reserva"
                columns={columns}
                formRoutePath="form"
                density="compact"
                findDisabled={!filterInitialized}
                fixedFilter={fixedFilter}
                toolbarHideQuickFilter
                onRefresh={() => obtenirTotals()}
                onDelete={() => obtenirTotals()}
                toolbarAdditionalActions={[
                    // {
                    //     position: 2,
                    //     component: (
                    //         <IconButton
                    //             onClick={() => openTotalsDialog()}
                    //             title={t("reserves.totals.title")}
                    //         >
                    //             <Icon>calculate</Icon>
                    //         </IconButton>
                    //     ),
                    // },
                    {
                        position: 7,
                        component: (
                            <BotoExportacio
                                resourceName={"reserva"}
                                exportData={exportData}
                                // exportCallbacks={exportCallbacks}
                            />
                        ),
                    },
                ]}
                toolbarAdditionalRow={
                    <>
                        <ReservesFilter
                            onSpringFilterChange={setFixedFilter}
                            onFilterInitialized={() =>
                                setFilterInitialized(true)
                            }
                            setExportFixedFilter={setExportFixedFilter}
                        />
                        {totals && (
                            <Box sx={{ mb: 2 }}>
                                <TotalsContent totals={totals} />
                            </Box>
                        )}
                    </>
                }
                rowHideEditViewAction
                rowAdditionalActions={(params) =>
                    rowAdditionalActions(params.row)
                }
                initialState={{
                    pinnedColumns: { left: [], right: ["actions"] },
                }}
                defaultSortModel={[
                    {
                        field: "data",
                        sort: "desc",
                    },
                ]}
                getCellClassName={({ row, field }) => {
                    if (row?.estat === "ANULADA") {
                        return "cellAnulada";
                    }
                    return row?.entradaManual ||
                        row?.liniaManual ||
                        row?.observacions
                        ? "cellWarning"
                        : "";
                }}
            />
            {/* {dialog} */}
        </Box>
    );
};

export default Reserves;
