import Grid from '@mui/material/Grid';
import { FormField } from '@programari-limit/base-react';
import PropTypes from 'prop-types';

const GridFormField = (props) => {
    const { name, xs, sm, md, lg, xl, ...rest } = props;
    return (
        <Grid item xs={xs ?? 12} sm={sm} md={md} lg={lg} xl={xl}>
            <FormField name={name} {...rest} />
        </Grid>
    );
};

GridFormField.propTypes = {
    ...FormField.propTypes,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
};

export default GridFormField;
