import { createTheme } from '@mui/material/styles';
import { defaultTheme as defaultBaseTheme, useModuleContext } from '@programari-limit/base-react';

const getImageGradient = (headerColor, background) => {
    const gradient = 'linear-gradient(to right, ' + headerColor + 'FF 10%, ' + headerColor + '00 50% 50%, ' + headerColor + 'FF),';
    return gradient + `url(${background})`;
}

const useAppLogic = (headerColor, headerBackground) => {
    const {
        currentRoutes,
        currentRouteIndex,
        initialized: moduleInitialized
    } = useModuleContext(false);
    return {
        appTheme: createTheme({
            palette: {
                primary: {
                    main: headerColor,
                },
            },
            ...defaultBaseTheme
        }),
        appHeaderStyle: {
            backgroundImage: getImageGradient(headerColor, headerBackground),
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
        appRoutes: currentRoutes,
        appRouteIndex: currentRouteIndex,
        appRoutesInitialized: moduleInitialized,
    }
}

export default useAppLogic;