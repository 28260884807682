import React from "react";
import caLocale from "date-fns/locale/ca";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Scheduler as SchedulerComp } from "@aldabil/react-scheduler";
import {
    useResourceApi,
    ASYNC_STATUS_PENDING,
    ASYNC_STATUS_SUCCESS,
    ActionButton,
    useBaseAppContext,
} from "@programari-limit/base-react";
import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ActionPlanificarContent } from "pages/AgendesProducte";

const translationsCa = {
    navigation: {
        month: "Mes",
        week: "Setmana",
        day: "Dia",
        today: "Avui",
        agenda: "Agenda",
    },
    form: {
        addTitle: "Afegir event",
        editTitle: "Modificar event",
        confirm: "Confirmar",
        delete: "Esborrar",
        cancel: "Cancel·lar",
    },
    event: {
        title: "Títol",
        start: "Inici",
        end: "Fi",
        allDay: "Tot lo dia",
    },
    validation: {
        required: "Obligatori",
        invalidEmail: "Adreça de correu incorrecta",
        onlyNumbers: "Només nombres",
        min: "Mínim {{min}} caràcters",
        max: "Màxim {{max}} caràcters",
    },
    moreEvents: "Més...",
    noDataToDisplay: "Sense informació per mostrar",
    loading: "Carregant...",
};

const translationsEs = {
    navigation: {
        month: "Mes",
        week: "Semana",
        day: "Día",
        today: "Hoy",
        agenda: "Agenda",
    },
    form: {
        addTitle: "Añadir evento",
        editTitle: "Modificar evento",
        confirm: "Confirmar",
        delete: "Borrar",
        cancel: "Cancelar",
    },
    event: {
        title: "Título",
        start: "Inicio",
        end: "Fin",
        allDay: "Todo el día",
    },
    validation: {
        required: "Obligatorio",
        invalidEmail: "Dirección de correo incorrecta",
        onlyNumbers: "Solo números",
        min: "Mínimo {{min}} caracteres",
        max: "Máximo {{max}} caracteres",
    },
    moreEvents: "Más...",
    noDataToDisplay: "Sin información para mostrar",
    loading: "Cargando...",
};

export const Scheduler = (props) => {
    const {
        title,
        resourcesResourceName,
        eventsResourceName,
        initialDate,
        initialView = "month",
        resourceFieldsMap,
        eventFieldsMap,
        customEventFields,
        viewerExtraComponent,
        toggleComponent,
    } = props;
    const [selectedDate, setSelectedDate] = React.useState(initialDate);
    const [selectedView, setSelectedView] = React.useState(initialView);
    const [findRequestFilter, setFindRequestFilter] = React.useState();
    const [resourcesFindStatus, setResourcesFindStatus] = React.useState();
    const [eventsFindStatus, setEventsFindStatus] = React.useState();
    const [resources, setResources] = React.useState();
    const [events, setEvents] = React.useState([]);
    const [checked, setChecked] = React.useState(false);
    const { apiInitialized: resourcesApiInitialized, exec: resourcesApiExec } =
        useResourceApi(resourcesResourceName);
    const { apiInitialized: eventsApiInitialized, exec: eventsApiExec } =
        useResourceApi(eventsResourceName);
    const resourcesRequestFinished =
        !resourcesResourceName || resourcesFindStatus === ASYNC_STATUS_SUCCESS;
    const { t } = useTranslation();
    const { currentLanguage } = useBaseAppContext();

    React.useEffect(() => {
        if (resourcesResourceName && resourcesApiInitialized) {
            resourcesApiExec("find", {
                data: {
                    page: "UNPAGED",
                    sort: ["id,asc"],
                },
                callbacks: {
                    setStatus: setResourcesFindStatus,
                    setError: (error) => {
                        console.error(error);
                    },
                    setResponse: (response) => {
                        const resources = response.getEmbedded()?.map((e) => {
                            const d = e.data;
                            if (
                                resourceFieldsMap &&
                                typeof resourceFieldsMap === "function"
                            ) {
                                return resourceFieldsMap(d);
                            } else {
                                const fieldsMap = resourceFieldsMap ?? {
                                    assignee: "assignee",
                                    text: "text",
                                    subtext: "subtext",
                                };
                                return {
                                    assignee: d[fieldsMap.assignee],
                                    text: d[fieldsMap.text],
                                    subtext: d[fieldsMap.subtext],
                                };
                            }
                        });
                        setResources(resources);
                    },
                },
                refresh: true,
            });
        }
    }, [resourcesApiInitialized]);

    React.useEffect(() => {
        if (
            resourcesRequestFinished &&
            eventsApiInitialized &&
            findRequestFilter
        ) {
            eventsApiExec("find", {
                data: {
                    page: "UNPAGED",
                    filter: findRequestFilter,
                },
                callbacks: {
                    setStatus: setEventsFindStatus,
                    setError: (error) => {
                        console.error(error);
                    },
                    setResponse: (response) => {
                        const events = response.getEmbedded()?.map((e) => {
                            const d = e.data;
                            if (
                                eventFieldsMap &&
                                typeof eventFieldsMap === "function"
                            ) {
                                return eventFieldsMap(d);
                            } else {
                                const fieldsMap = eventFieldsMap ?? {
                                    event_id: "event_id",
                                    title: "title",
                                    start: "start",
                                    end: "end",
                                    assignee: "assignee",
                                };
                                return {
                                    event_id: d[fieldsMap.event_id],
                                    title: d.requeriment.description,
                                    start: new Date(d.data + "T" + d.horaInici),
                                    end: new Date(d.data + "T" + d.horaFi),
                                    assignee: resourcesResourceName
                                        ? d.producte.id
                                        : undefined,
                                };
                            }
                        });
                        //console.log('>>> setEvents', events)
                        setEvents(events);
                    },
                },
                refresh: true,
            });
        }
    }, [resourcesRequestFinished, eventsApiInitialized, findRequestFilter]);

    React.useEffect(() => {
        if (selectedView === "month") {
            const firstDay = selectedDate;
            firstDay.setDate(1);
            const initialDate = firstDay.toISOString().split("T")[0];
            const finalDate = new Date(
                new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 1) - 1
            )
                .toISOString()
                .split("T")[0];
            setFindRequestFilter(
                "data>:'" + initialDate + "' and data<:'" + finalDate + "'"
            );
        } else if (selectedView === "week") {
            const initialDate = selectedDate.toISOString().split("T")[0];
            const lastDay = selectedDate;
            lastDay.setDate(lastDay.getDate() + 6);
            const finalDate = lastDay.toISOString().split("T")[0];
            setFindRequestFilter(
                "data>:'" + initialDate + "' and data<:'" + finalDate + "'"
            );
        } else if (selectedView === "day") {
            setFindRequestFilter("data:'" + initialDate + "'");
        }
    }, [selectedDate, selectedView]);

    const handleSelectedDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleViewChange = (view) => {
        setSelectedView(view);
    };
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const showComponent =
        resourcesRequestFinished &&
        (!resourcesResourceName || resources != null);

    return showComponent ? (
        <>
            <Toolbar
                disableGutters
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <Typography variant="h6">{title}</Typography>
                    {toggleComponent}
                </Box>
                <Box sx={{ display: "flex", mr: 1 }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={checked}
                                    onChange={handleChange}
                                />
                            }
                            label={t(
                                "agendesProducte.calendari.switchProductes"
                            )}
                        />
                    </FormGroup>
                    <ActionButton
                        resourceName="agendaProducte"
                        code="AP_PLANIFICAR"
                        title={t("agendesProducte.actions.planificar.title")}
                        detached
                        variant="contained"
                        formDialogSize="lg"
                        formChildren={<ActionPlanificarContent />}
                        onSuccess={() => {
                            gridApiRef?.current?.doRefresh();
                        }}
                    />
                </Box>
            </Toolbar>
            <SchedulerComp
                key={`${checked}${currentLanguage}`}
                events={events}
                resources={!checked ? resources : []}
                selectedDate={initialDate}
                view={initialView}
                resourceViewMode="tabs"
                viewerExtraComponent={viewerExtraComponent}
                onSelectedDateChange={handleSelectedDateChange}
                onViewChange={handleViewChange}
                locale={caLocale}
                month={{
                    weekDays: [0, 1, 2, 3, 4, 5, 6],
                    weekStartOn: 1,
                    startHour: 8,
                    endHour: 20,
                }}
                week={{
                    weekDays: [0, 1, 2, 3, 4, 5, 6],
                    weekStartOn: 1,
                    startHour: 8,
                    endHour: 20,
                    step: 30,
                }}
                day={{
                    startHour: 8,
                    endHour: 20,
                    step: 30,
                }}
                loading={
                    resourcesFindStatus === ASYNC_STATUS_PENDING ||
                    eventsFindStatus === ASYNC_STATUS_PENDING
                }
                editable={false}
                deletable={false}
                draggable={false}
                hourFormat={24}
                translations={
                    currentLanguage === "ca" ? translationsCa : translationsEs
                }
            />
        </>
    ) : null;
};

export default Scheduler;
