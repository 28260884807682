import React from 'react';
import { Box, Typography } from '@mui/material';

const Home = () => {
    return <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 72px)',
        }}>
         <Typography variant="h2">Creuers</Typography>
    </Box>;
}

export default Home;