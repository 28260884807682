import { SpringFilterQueryBuilder as builder } from "utils/spring-filter-query-builder";

export const decodeId = (id) => {
    if (id) {
        const decodedToken = atob(id);
        const jsonToken = JSON.parse(decodedToken);
        return jsonToken;
    }
};

export const equalIfNotNullArray = (valuesArray) => {
    return valuesArray
        .map((valueArray) =>
            valueArray[1] != null ? builder.equal(valueArray[0], valueArray[1]) : undefined
        )
        .filter((item) => item != null);
};

export const genericIfNotNullArray = (valuesArray) => {
    return valuesArray
        .map((valueArray) =>
            valueArray[1] != null ? valueArray[2](valueArray[0], valueArray[1]) : undefined
        )
        .filter((item) => item != null);
};

export const likeAny = (value) => {
    if(value == null)
        return undefined
    return `*${value}*`
}
export const likeEnd = (value) => {
    if(value == null)
        return undefined
    return `${value}*`
}
export const likeStart = (value) => {
    if(value == null)
        return undefined
    return `*${value}`
}
