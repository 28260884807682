import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Paper, Typography } from "@mui/material";
import { useBaseAppContext } from "@programari-limit/base-react";

const SelectorIdioma = () => {
    const { currentLanguage, setCurrentLanguage } = useBaseAppContext();

    const handleChange = (event) => {
        setCurrentLanguage(event);
    };

    return (
        <Paper
            elevation={2}
            sx={{ display: "flex", gap: 1, mr: 2, px: 2, py: 1 }}
        >
            <Typography
                onClick={() => handleChange("ca")}
                sx={{
                    cursor: "pointer",
                    fontWeight: currentLanguage === "ca" ? "bold" : "normal",
                    color: currentLanguage !== "ca" && "#b3b3b3",
                }}
            >
                Ca
            </Typography>
            <Divider
                orientation="vertical"
                flexItem
                sx={{ border: "1px solid black" }}
            />
            <Typography
                onClick={() => handleChange("es")}
                sx={{
                    cursor: "pointer",
                    fontWeight: currentLanguage === "es" ? "bold" : "normal",
                    color: currentLanguage !== "es" && "#b3b3b3",
                }}
            >
                Es
            </Typography>
        </Paper>
    );
};
export default SelectorIdioma;
