import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Form, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import RequisitsRecursos from "./RequisitsRecursos";
import { columnesZones } from "sharedAdvancedSearch/advancedSearchColumns";

const ProductesForm = () => {
    return (
        <MuiGrid container spacing={2} sx={{ mt: 2 }}>
            <GridFormField name="codi" lg={1.5} />
            <GridFormField name="nom" lg={4.5} />
            <GridFormField name="nomTicket" lg={4.5} />
            <GridFormField name="actiu" lg={1.5} />
            <GridFormField
                name="zona"
                lg={4}
                advancedSearchColumns={columnesZones}
            />
            <GridFormField name="tipus" lg={4} />
            <GridFormField
                name="zonaDesti"
                lg={4}
                advancedSearchColumns={columnesZones}
            />
            <GridFormField name="descripcio" lg={12} />
            <GridFormField name="observacions" lg={12} />
        </MuiGrid>
    );
};

const ProductesFormContent = () => {
    const { t } = useTranslation();
    const { data } = useFormContext();
    const [value, setValue] = React.useState("0");

    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label={t("indicadores.common.form.tabs")}
                >
                    <Tab label={t("productes.update")} value="0" />
                    <Tab
                        label={t("requisitsRecurs.title")}
                        value={"1"}
                        disabled={!data?.id}
                    />
                </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: "100%", p: 0 }}>
                <ProductesForm />
            </TabPanel>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
                <RequisitsRecursos isDetallProducte={true} />
            </TabPanel>
        </TabContext>
    );
};

const ProductesDetall = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <Form
                resourceName="producte"
                id={id}
                titleCreate={t("productes.create")}
                titleUpdate={t("productes.update")}
                onGoBack={() => navigate(-1)}
                size="small"
                onCreate={(data) => {
                    navigate(data.id.toString());
                }}
            >
                <ProductesFormContent />
            </Form>
        </Box>
    );
};
export default ProductesDetall;
