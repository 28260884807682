import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
    Grid,
    useBaseAppContext,
    useFormContext,
} from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { Typography } from "@mui/material";
import TwoLayerContainer from "components/TwoLayerContainer";
import {
    columnesProducte,
    columnesZones,
} from "sharedAdvancedSearch/advancedSearchColumns";

export const RequerimentRecursosGrid = (props) => {
    const { currentOpenRequisitRecurs } = props;
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const columns = [
        // {
        //     name: "codi",
        //     flex: 1,
        // },
        {
            name: "recurs",
            flex: 3,
        },
        {
            name: "zona",
            flex: 3,
            formFieldProps: {
                advancedSearchColumns: columnesZones,
            },
        },
        {
            name: "desde",
            flex: 2,
        },
        {
            name: "tempsDesde",
            flex: 1.5,
                    },
        {
            name: "fins",
            flex: 2,
        },

        {
            name: "tempsFins",
            flex: 1.5,
        },
    ];

    return (
        // <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Grid
            title={t("requerimentsRecurs.title")}
            resourceName="requerimentRecurs"
            columns={columns}
            density={"compact"}
            fixedFilter={`requeriment.id : '${currentOpenRequisitRecurs?.id}'`}
            inlineFormStaticData={{
                requeriment: {
                    id: currentOpenRequisitRecurs?.id,
                },
            }}
        />
        // </Box>
    );
};

const RequisitsRecursos = (props) => {
    const { isDetallProducte } = props;
    const { t } = useTranslation();
    const [currentOpenRequisitRecurs, setCurrentOpenRequisitRecurs] =
        React.useState(); //Se usa para el componenteTwoLayerContainer
    const { currentLanguage } = useBaseAppContext();
    const gridApiRef = React.useRef();
    const { data } = useFormContext();
    const columns = [
        // {
        //     name: "codi",
        //     flex: 1,
        // },
        {
            name: "nom",
            flex: 3,
        },
        {
            name: "observacions",
            flex: 5,
        },
    ];
    const { setContentExpandsToAvailableHeight } = useBaseAppContext();

    //Funcion para abrir y cerrar TwoLayerContainer
    const handleEditRequisitRecursButtonClick = (params) => {
        setCurrentOpenRequisitRecurs(params.row);
    };

    const rowAdditionalActions = (params) => {
        const listActions = [
            {
                icon: "settings",
                label: t("requerimentsRecurs.title"),
                onClick: (params) =>
                    handleEditRequisitRecursButtonClick(params),
                showInMenu: false,
            },
        ];
        if (isDetallProducte) {
            listActions.push({
                icon: "content_copy",
                label: "Duplicar",
                showInMenu: true,
                rowLinkAction: "R_DUPLICAR",
                rowLinkActionOnSuccess: () => gridApiRef?.current?.doRefresh(),
                rowLinkFormStaticData: {
                    nomRequeriment: params?.row?.nom + " (copia)",
                    producte: params?.row?.producte,
                },
                rowLinkFormChildren: (
                    <MuiGrid container spacing={2} sx={{ mt: 1 }}>
                        <GridFormField name="codiRequeriment" lg={4} />
                        <GridFormField name="nomRequeriment" lg={8} />
                        <GridFormField
                            name="producte"
                            lg={12}
                            advancedSearchColumns={columnesProducte}
                            advancedSearchMaxWidth="lg"
                        />
                    </MuiGrid>
                ),
            });
        }

        return listActions;
    };

    // Todo: S'ha posat aixo pq la llibreria no refresca la traduccio de l'accio sense refrescar el grid
    React.useEffect(() => {
        gridApiRef?.current?.doRefresh?.();
    }, [currentLanguage]);

    //TODO. Aquest useEffect es fa per solucionar un problema de la llibreria, dins GridCore L.723-729
    React.useEffect(() => {
        setContentExpandsToAvailableHeight(true);
        return () => setContentExpandsToAvailableHeight(false);
    }, [currentOpenRequisitRecurs]);

    return (
        <TwoLayerContainer
            open={currentOpenRequisitRecurs !== undefined}
            title={
                data?.codi +
                " - " +
                data?.nom +
                " / " +
                currentOpenRequisitRecurs?.nom
            }
            onClose={() => {
                setCurrentOpenRequisitRecurs(undefined);
                gridApiRef?.current?.doRefresh();
            }}
            baseLayer={
                <>
                    <Typography variant="h6" sx={{ p: 2 }}>
                        {data?.codi + " - " + data?.nom}
                    </Typography>
                    <Grid
                        apiRef={gridApiRef}
                        title={t("requisitsRecurs.title")}
                        resourceName="requeriment"
                        density={"compact"}
                        columns={columns}
                        rowHideEditViewAction
                        actionExecEnabled
                        rowAdditionalActions={(params) =>
                            rowAdditionalActions(params)
                        }
                        inlineFormStaticData={{ producte: { id: data?.id } }}
                        fixedFilter={`producte.id : ${data?.id}`}
                    />
                </>
            }
            topLayer={
                <RequerimentRecursosGrid
                    currentOpenRequisitRecurs={currentOpenRequisitRecurs}
                />
            }
        />
    );
};

export default RequisitsRecursos;
