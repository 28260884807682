import * as React from "react";
import { Form } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Parametres = () => {
    const { t } = useTranslation();

    return (
        <Form
            id={1}
            resourceName="parametre"
            titleUpdate={t("parametres.title")}
            size="small"
        >
            <MuiGrid container spacing={2}>
                <MuiGrid item lg={12} sx={{ mt: 1 }}>
                    <Divider textAlign="left">
                        <Typography sx={{ fontWeight: "500" }}>
                            {t("parametres.titleMobil")}
                        </Typography>
                    </Divider>
                </MuiGrid>
                <GridFormField name="covesPreuAdult" lg={3} suffix={" €"} />
                <GridFormField name="covesPreuInfant" lg={3} suffix={" €"} />

                <MuiGrid item lg={12} sx={{ mt: 4 }}>
                    <Divider textAlign="left">
                        <Typography sx={{ fontWeight: "500" }}>
                            {t("parametres.titleComptabilitat")}
                        </Typography>
                    </Divider>
                </MuiGrid>
                <GridFormField name="idProfessionalVaixell" lg={4} />
                <GridFormField name="raoSocialVaixell" lg={4} />
                <GridFormField name="codiComptableVaixell" lg={4} />

                <GridFormField name="idProfessionalCoves" lg={4} />
                <GridFormField name="raoSocialCoves" lg={4} />
                <GridFormField name="codiComptableCoves" lg={4} />
            </MuiGrid>
        </Form>
    );
};

export default Parametres;
