import { Box, Icon, IconButton, Typography } from '@mui/material';

const TwoLayerContainer = ({ baseLayer, topLayer, title, open, onClose }) => {
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    display: open ? 'none' : 'block',
                }}
            >
                {baseLayer}
            </Box>
            {open && (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        // overflowY: 'scroll'
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                px: 2,
                                py: 2,
                            }}
                        >
                            <Typography variant="h6">{title}</Typography>
                            <IconButton onClick={onClose}>
                                <Icon>close</Icon>
                            </IconButton>
                        </Box>
                    </Box>
                    {topLayer}
                </Box>
            )}
        </Box>
    );
};

export default TwoLayerContainer;
