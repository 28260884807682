import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
    Grid,
    Form,
    ActionButton,
    useBaseAppContext,
    useResourceApi,
    Filter,
} from "@programari-limit/base-react";
import GridFormField from "components/GridFormField";
import MuiGrid from "@mui/material/Grid";
import { suffixValueFormatter } from "utils/muiUtils";
import {
    Box,
    Divider,
    Icon,
    IconButton,
    Tooltip,
    Typography,
    darken,
} from "@mui/material";
import { getFielYellow } from "./Reserves";
import GridFilterField from "components/GridFilterField";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import {
    columnesUsuaris,
    columnesZones,
} from "sharedAdvancedSearch/advancedSearchColumns";

const TotalsContent = (props) => {
    const { totals } = props;
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 5,
                mt: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("informes.totals.efectiu")}
                </Typography>
                <Typography>{totals?.totalEfectiu + " €"}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("informes.totals.targeta")}
                </Typography>
                <Typography>{totals?.totalTarjeta + " €"}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("informes.totals.tiquets")}
                </Typography>
                <Typography>{totals.totalTiquets + " €"}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("informes.totals.coves")}
                </Typography>
                <Typography>{totals.totalCoves + " €"}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("informes.totals.total")}
                </Typography>
                <Typography>{totals.totalImport + " €"}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">
                    {t("informes.totals.resultats")}
                </Typography>
                <Typography>{totals.totalInformesZonaVenedor}</Typography>
            </Box>
        </Box>
    );
};

const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField name="dataInici" sm={6} lg={2.5} xl={2} />
            <GridFilterField name="dataFi" sm={6} lg={2.5} xl={2} />
            <GridFilterField
                name="user"
                sm={5.5}
                lg={3.5}
                xl={4}
                advancedSearchColumns={columnesUsuaris}
            />
            <GridFilterField
                name="zona"
                xs={11}
                sm={5.5}
                lg={3}
                xl={3.5}
                advancedSearchColumns={columnesZones}
            />
            <MuiGrid item xs={0.5} lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const springFilterBuilder = (data, setExportFixedFilter) => {
    const { and, equal, ge, le, like, exists, isNotNull } =
        SpringFilterQueryBuilder;

    // exportSpringFilterBuilder(data, setExportFixedFilter);
    const filter = and(
        ge("dataInforme", data?.dataInici),
        le("dataInforme", data?.dataFi),
        equal("zona.id", data?.zona?.id),
        equal("user.id", data?.user?.id)
    ).toString();

    return filter;
};

const InformeZonaVenedorFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized, setExportFixedFilter } =
        props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"IZV_FILTER"}
                resourceName={"informeZonaVenedor"}
                persistentState
                springFilterBuilder={(data) =>
                    springFilterBuilder(data, setExportFixedFilter)
                }
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
                // initialData={{ estat: "ACTIVA" }}
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

export const InformeZonaVenedorForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    return (
        <Form
            resourceName="informeZonaVenedor"
            id={id}
            titleCreate={t("informes.create")}
            titleUpdate={t("informes.update")}
            onGoBack={() => navigate(-1)}
        >
            <MuiGrid container spacing={2}>
                <GridFormField name="dataCalcul" lg={2.5} />
                <GridFormField name="dataInforme" lg={1.5} />
                <GridFormField
                    name="user"
                    lg={4}
                    advancedSearchColumns={columnesUsuaris}
                />
                <GridFormField
                    name="zona"
                    lg={4}
                    advancedSearchColumns={columnesZones}
                />
                <GridFormField name="totalEfectiu" lg={2} suffix={" €"} />
                <GridFormField name="totalTarjeta" lg={2} suffix={" €"} />
                <GridFormField name="totalTiquets" lg={2} suffix={" €"} />
                <GridFormField name="totalCoves" lg={2} suffix={" €"} />
                <GridFormField name="totalImport" lg={2} suffix={" €"} />
                <GridFormField name="observacions" lg={12} />
            </MuiGrid>
        </Form>
    );
};

const CalcularDadesForm = () => {
    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            <GridFormField name="dataInici" lg={6} />
            <GridFormField name="dataFi" lg={6} />
            <GridFormField name="observacions" lg={12} />
        </MuiGrid>
    );
};

const InformeZonaVenedor = () => {
    const { t } = useTranslation();
    const gridApiRef = React.useRef();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const [exportFixedFilter, setExportFixedFilter] = React.useState();
    const [totals, setTotals] = React.useState();
    const { currentLanguage } = useBaseAppContext();
    const { apiInitialized, exec: apiExec } =
        useResourceApi("informeZonaVenedor");

    const columns = [
        {
            name: "dataInforme",
            flex: 0.8,
        },
        {
            name: "user",
            flex: 1.2,
        },
        {
            name: "zona",
            flex: 1,
        },
        {
            name: "totalEfectiu",
            flex: 0.8,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "totalTarjeta",
            flex: 0.8,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "totalTiquets",
            flex: 0.8,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "totalCoves",
            flex: 0.8,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
        {
            name: "totalImport",
            flex: 1,
            type: "currency",
            currencyDecimalPlaces: 2,
            formFieldProps: {
                suffix: " €",
            },
            valueFormatter: (params) => suffixValueFormatter(params),
        },
    ];

    React.useEffect(() => {
        gridApiRef?.current?.doRefresh();
    }, [currentLanguage]);

    const obtenirTotals = () => {
        apiExec("exec_IZV_OBTENIR_TOTALS", {
            data: { springFilter: fixedFilter ?? "" },
            callbacks: {
                setResponse: (response) => {
                    const resource = {
                        ...response.data,
                        _resource: response,
                        _links: response.links,
                    };
                    setTotals(resource);
                },
                setError: (error) => {},
            },
        });
    };

    React.useEffect(() => {
        if (apiInitialized) {
            obtenirTotals();
        }
    }, [apiInitialized, fixedFilter]);

    return (
        <Box
            sx={{
                height: "100%",
                "& .cellWarning": {
                    bgcolor: (theme) => getFielYellow(theme.palette.mode),
                },
                "& .MuiDataGrid-row:hover .cellWarning": {
                    bgcolor: (theme) =>
                        `${darken(
                            getFielYellow(theme.palette.mode),
                            0.1
                        )}!important`,
                },
            }}
        >
            <Grid
                apiRef={gridApiRef}
                title={t("informes.title")}
                resourceName="informeZonaVenedor"
                fixedFilter={fixedFilter}
                columns={columns}
                formRoutePath="form"
                density="compact"
                onRefresh={() => obtenirTotals()}
                toolbarAdditionalRow={
                    <>
                        <InformeZonaVenedorFilter
                            onSpringFilterChange={setFixedFilter}
                            onFilterInitialized={() =>
                                setFilterInitialized(true)
                            }
                            setExportFixedFilter={setExportFixedFilter}
                        />
                        {totals && (
                            <Box sx={{ mb: 2 }}>
                                <TotalsContent totals={totals} />
                            </Box>
                        )}
                    </>
                }
                toolbarAdditionalActions={[
                    {
                        position: 2,
                        component: (
                            <ActionButton
                                resourceName="informeZonaVenedor"
                                code="IZV_CALCULAR_DADES"
                                // title={t("agendesProducte.actions.seleccionar")}
                                detached
                                variant="contained"
                                icon="polyline"
                                formChildren={<CalcularDadesForm />}
                                onSuccess={() => {
                                    obtenirTotals();
                                    gridApiRef.current.doRefresh();
                                }}
                            />
                        ),
                    },
                ]}
                defaultSortModel={[
                    {
                        field: "dataInforme",
                        sort: "desc",
                    },
                ]}
                getCellClassName={({ row, field }) => {
                    return row?.observacions ? "cellWarning" : "";
                }}
            />
        </Box>
    );
};

export default InformeZonaVenedor;
