import Grid from '@mui/material/Grid';
import { FilterField } from '@programari-limit/base-react';
import PropTypes from 'prop-types';

const GridFilterField = (props) => {
    const { name, xs, sm, md, lg, xl, ...rest } = props;
    return (
        <Grid item xs={xs ?? 12} sm={sm} md={md} lg={lg} xl={xl}>
            <FilterField name={name} {...rest} />
        </Grid>
    );
};

GridFilterField.propTypes = {
    ...FilterField.propTypes,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
};
export default GridFilterField;
