import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BaseApp, useBaseAppContext } from '@programari-limit/base-react';
import AppRoutes from './AppRoutes';
import useAppLogic from './hooks/useAppLogic';
import defaultLogo from './assets/logo.png';
import defaultHeaderBackground from './assets/headerBackground.jpg';
import SelectorIdioma from 'components/SelectorIdioma';

const App = () => {
    const { temporalMessageComponent, setCurrentLanguage } = useBaseAppContext();
    const {
        appTheme,
        appHeaderStyle,
    } = useAppLogic('#32814B', defaultHeaderBackground);

    React.useEffect(()=> {
        const idioma = (navigator.language || navigator.userLanguage)?.split('-')[0];
        setCurrentLanguage(idioma)
    },[])

    return <ThemeProvider theme={appTheme}>
        <BaseApp
            image={defaultLogo}
            headerStyle={appHeaderStyle}
            name={'Creuers'}
            additionalHeaderComponent={<SelectorIdioma />}
            minWidthZero>
            <AppRoutes />
            {temporalMessageComponent}
        </BaseApp>
    </ThemeProvider>;
}

export default App;