import { Button, Icon, IconButton, MenuItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

/**
 * Componente usado para mostrar un ActionButton
 * usando el componente MenuItem
 */
export const MenuItemButtonComponent = (props) => {
    const { title, icon, disabled, loading, initialized, onClick } = props;
    return (
        <MenuItem
            onClick={() => {
                onClick();
            }}
            disabled={disabled || loading || !initialized}
        >
            <ListItemIcon>
                <Icon>{icon ?? 'copy'}</Icon>
            </ListItemIcon>
            <ListItemText primary={title} />
        </MenuItem>
    );
};

/**
 * Componente usado para mostrar un ActionButton
 * usando el componente MenuItem
 */
export const IconButtonButtonComponent = (props) => {
    const { title, icon, disabled, loading, initialized, onClick } = props;
    return (
        <IconButton
            title={title}
            disabled={disabled || loading || !initialized}
            onClick={() => {
                onClick();
            }}
        >
            <Icon>{icon ?? 'add'}</Icon>
        </IconButton>
    );
};

export const ButtonComponent = (props) => {
    const { title, icon, disabled, loading, initialized, onClick } = props;
    return (
        <Button
            startIcon={<Icon>{icon}</Icon>}
            disabled={disabled || loading || !initialized}
            onClick={() => {
                onClick();
            }}
        >
            {title}
        </Button>
    );
};
