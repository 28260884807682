import { suffixValueFormatter } from "utils/muiUtils";

export const columnesProducte = [
    {
        name: "codi",
        flex: 0.8,
    },
    {
        name: "nom",
        flex: 2,
    },
    {
        name: "zona",
        flex: 0.8,
    },
    {
        name: "tipus",
        flex: 0.8,
    },
    {
        name: "actiu",
        flex: 0.5,
    },
];
export const columnesRequisitRecurs = [
    {
        name: "codi",
        flex: 0.8,
    },
    {
        name: "nom",
        flex: 2,
    },
    {
        name: "producte",
        flex: 1.5,
    },
];
export const columnesZones = [
    {
        name: "codi",
        flex: 0.8,
    },
    {
        name: "nom",
        flex: 2,
    },
];
export const columnesUsuaris = [
    {
        name: "code",
        flex: 0.8,
    },
    {
        name: "givenName",
        flex: 2,
    },
    {
        name: "familyName",
        flex: 2,
    },
    {
        name: "email",
        flex: 2,
    },
];
export const columnesAgendaProducte = [
    {
        name: "data",
        flex: 1,
    },
    {
        name: "nom",
        flex: 2,
    },
    {
        name: "producte",
        flex: 2,
    },
    {
        name: "zona",
        flex: 2,
    },
    {
        name: "horaInici",
        flex: 1,
    },
    {
        name: "disponibilitat",
        flex: 1,
    },
];
export const columnesClients = [
    {
        name: "codi",
        flex: 0.8,
    },
    {
        name: "nom",
        flex: 2,
    },
    {
        name: "tipus",
        flex: 0.8,
    },
    {
        name: "preu",
        flex: 0.8,
        type: "currency",
        currencyDecimalPlaces: 2,
        formFieldProps: {
            suffix: " €",
        },
        valueFormatter: (params) => suffixValueFormatter(params),
    },
];
