import { Item } from './item.js';
import { Comparator } from './comparators.js';

class Operator extends Item { //Abstract
	constructor(operatorKeyWord, ...items) {
		super();
		if (new.target === Operator) {
			throw new TypeError("Cannot construct " + new.target.name + " instances directly");
		}
		this.operatorKeyWord = operatorKeyWord;
		this.items = items;
	}

	toString() {
		const itemsToString = this.items.map(item => item?.toString());
		const filteredItems = itemsToString.filter(item => item !== "" && item != null);

		if(filteredItems.length === 0) return "";
		return  "(" + filteredItems.join(` ${this.operatorKeyWord} `) + ")";
	}
}

class And extends Operator {
	constructor(...items) {
		super("AND", ...items);
	}
}

class Or extends Operator {
	constructor(...items) {
		super("OR", ...items);
	}
}

class PrependOperator extends Operator { //Abstract
    constructor(...params) {
        super(...params);
        if (new.target === PrependOperator) {
            throw new TypeError('Cannot construct ' + new.target.name + ' instances directly');
        }
    }

    toString() {
        if (this.items[0] == null || this.items[0].toString() === '') return '';

        if (this.items[0] instanceof Operator) {
            return `${this.operatorKeyWord}${this.items[0].toString()}`;
        }
        return `${this.operatorKeyWord}(${this.items[0].toString()})`;
    }
}

class Not extends PrependOperator {
    constructor(item) {
        if (!(item instanceof Operator || item instanceof Comparator)) {
            throw `${new.target.name} parameter 'item' must be a Operator or a Comparator!`;
        }
        super('NOT', item);
    }
}

class Exists extends PrependOperator {
    constructor(item) {
        if (!(item instanceof Operator || item instanceof Comparator)) {
            throw `${new.target.name} parameter 'item' must be a Operator or a Comparator!`;
        }
        super('EXISTS', item);
    }
}

export { Operator, And, Or, Not, Exists };