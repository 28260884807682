import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const NotFound = () => {
    const { t } = useTranslation();
    return <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 72px)',
        }}>
        <Typography variant="h2">[404] {t('page.notFound')}</Typography>
    </Box>;
}

export default NotFound;