import React from "react";
import {
    Filter,
    dateFormatLocale,
    numberFormat,
    useBaseAppContext,
    timeFormatLocale,
} from "@programari-limit/base-react";
import ReactApexChart from "react-apexcharts";
import {
    Alert,
    Box,
    CircularProgress,
    Icon,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from "@mui/material";
import useChartRequest from "hooks/useChartRequest";
import GridFilterField from "components/GridFilterField";
import MuiGrid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

const LoadingChart = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 2,
            }}
        >
            <CircularProgress />
        </Box>
    );
};

const DateFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized } = props;
    const filterApiRef = React.useRef();
    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"AP_GRAFICS_FILTER"}
                resourceName={"agendaProducte"}
                persistentState
                springFilterBuilder={(data) => data?.data}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
                initialOnChangeRequest
            >
                <MuiGrid container spacing={2}>
                    <GridFilterField name="data" />
                </MuiGrid>
            </Filter>
        </Box>
    );
};

const UsRecurs = () => {
    const { currentLanguage } = useBaseAppContext();
    const getChart = useChartRequest();
    const { t } = useTranslation();

    const [chartOcupacio, setChartOcupacio] = React.useState();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [paramsChartOcupacio, setParamsChartOcupacio] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [dateTime, setDateTime] = React.useState();

    const fetchChartOcupacio = () => {
        if (paramsChartOcupacio) {
            getChart("grafics/ocupacions", paramsChartOcupacio).then((data) => {
                let copiaChart = JSON.parse(JSON.stringify(data?.data));

                copiaChart.options.tooltip = {
                    followCursor: true,
                    y: {
                        formatter: function (val) {
                            return numberFormat(val, {}, currentLanguage);
                        },
                    },
                };
                copiaChart.options.xaxis.labels = {
                    formatter: function (val) {
                        return timeFormatLocale(val, true) + "h";
                    },
                };

                copiaChart.options.title = {
                    text: t("grafics.ocupacio"),
                    align: "center",
                };

                setChartOcupacio(copiaChart);
            });
        }
    };

    React.useEffect(() => {
        fetchChartOcupacio();
    }, [paramsChartOcupacio, currentLanguage]);

    React.useEffect(() => {
        if (refresh) {
            fetchChartOcupacio();
            setRefresh(false);
        }
    }, [refresh]);

    React.useEffect(() => {
        if (fixedFilter) {
            setParamsChartOcupacio("data=" + fixedFilter);
        } else {
            setParamsChartOcupacio(undefined);
        }
    }, [fixedFilter]);

    React.useEffect(() => {
        setDateTime(new Date());
    }, [chartOcupacio]);

    // console.log(">>", dateFormatLocale(dateTime), timeFormatLocale(dateTime));

    return (
        <Box>
            <Typography variant="h6" sx={{ py: 2 }}>
                {t("grafics.title")}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 3,
                    alignItems: "center",
                }}
            >
                <DateFilter
                    onSpringFilterChange={setFixedFilter}
                    onFilterInitialized={() => setFilterInitialized(true)}
                />
                {chartOcupacio && paramsChartOcupacio && (
                    <>
                        <Tooltip title={t("grafics.refrescar")}>
                            <IconButton
                                onClick={() => setRefresh(true)}
                                sx={{ mb: 2 }}
                            >
                                <Icon>refresh</Icon>
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Últim refresc"> */}
                        <Typography
                            sx={{
                                mb: 2,
                                py: 1,
                                px: 2,
                                borderRadius: 4,
                                backgroundColor: "#f5f5f5",
                            }}
                        >
                            {/* Darrera consulta:{" "} */}
                            {dateFormatLocale(dateTime) +
                                " " +
                                timeFormatLocale(dateTime)}
                        </Typography>
                        {/* </Tooltip> */}
                    </>
                )}
                {filterInitialized && !paramsChartOcupacio && (
                    <Alert severity="warning" sx={{ mb: 2, pr: 4, pl: 2 }}>
                        {t("grafics.alert")}
                    </Alert>
                )}
            </Box>
            {filterInitialized && paramsChartOcupacio && chartOcupacio ? (
                <Paper elevation={2} sx={{ p: 2 }}>
                    <ReactApexChart
                        options={chartOcupacio?.options}
                        series={chartOcupacio?.series}
                        type="line"
                        height={500}
                    />
                </Paper>
            ) : paramsChartOcupacio ? (
                <LoadingChart />
            ) : null}
        </Box>
    );
};

export default UsRecurs;
