const popupButtons = {
    cancel: {
        startIcon: 'clear',
    },
    close: {
        startIcon: 'clear',
    },
    delete: {
        contained: true,
        startIcon: 'delete',
    },
    revert: {
        contained: true,
        startIcon: 'undo',
    },
    create: {
        contained: true,
        startIcon: 'add',
    },
    update: {
        contained: true,
        startIcon: 'save',
    },
    exec: {
        contained: true,
        startIcon: 'flash_on',
    },
    generate: {
        contained: true,
        startIcon: 'description',
    },
    done: {
        contained: true,
        startIcon: 'done',
    },
}

export default popupButtons;